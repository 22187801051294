<template>
  <div>
    <div class="app-list" v-for="(item,index) in appGameList" :key="index">
      <div class="app-list-content">
        <div class="content-top">
          <div class="item　sc-wr3rvk-0" @click="detailsClick(item[0])" v-if="item[0] && item[0].iconUrl"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[0].Name.replace(/\s+/g, '') + '?gameId='+item[0].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[0].Name.replace(/\s+/g, '') + '?gameId='+item[0].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[0] && item[0].iconUrl" alt=""></a></div>
          <div class="item　sc-wr3rvk-0" @click="detailsClick(item[1])" v-if="item[1] && item[1].iconUrl"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[1].Name.replace(/\s+/g, '') + '?gameId='+item[1].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[1].Name.replace(/\s+/g, '') + '?gameId='+item[1].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[1] && item[1].iconUrl" alt=""></a></div>
          <div class="item　sc-wr3rvk-0" @click="detailsClick(item[2])" v-if="item[2] && item[2].iconUrl"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[2].Name.replace(/\s+/g, '') + '?gameId='+item[2].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[2].Name.replace(/\s+/g, '') + '?gameId='+item[2].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[2] && item[2].iconUrl" alt=""></a></div>
        </div>
        <div class="content-middle" v-if="(index + 1) % 2 !== 0">
          <div class="middle-left" v-if="item[3] && item[3].iconUrl">
            <div class="item　sc-wr3rvk-0" @click="detailsClick(item[3])"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[3].Name.replace(/\s+/g, '') + '?gameId='+item[3].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[3].Name.replace(/\s+/g, '') + '?gameId='+item[3].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[3] && item[3].iconUrl" alt=""></a></div>
          </div>
          <div class="middle-right">
            <div class="item-box" v-if="item[4] && item[4].iconUrl"><div class="item　sc-wr3rvk-0" @click="detailsClick(item[4])"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[4].Name.replace(/\s+/g, '') + '?gameId='+item[4].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[4].Name.replace(/\s+/g, '') + '?gameId='+item[4].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[4] && item[4].iconUrl" alt=""></a></div></div>
            <div class="item-box" v-if="item[5] && item[5].iconUrl"><div class="item　sc-wr3rvk-0" @click="detailsClick(item[5])"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[5].Name.replace(/\s+/g, '') + '?gameId='+item[5].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[5].Name.replace(/\s+/g, '') + '?gameId='+item[5].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[5] && item[5].iconUrl" alt=""></a></div></div>
          </div>
          <div></div>
        </div>
        <div class="content-middle" v-if="(index + 1) % 2 === 0">
          <div class="middle-right">
            <div class="item-box" v-if="item[3] && item[3].iconUrl"><div class="item　sc-wr3rvk-0" @click="detailsClick(item[3])"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[3].Name.replace(/\s+/g, '') + '?gameId='+item[3].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[3].Name.replace(/\s+/g, '') + '?gameId='+item[3].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[3] && item[3].iconUrl" alt=""></a></div></div>
            <div class="item-box" v-if="item[4] && item[4].iconUrl"><div class="item　sc-wr3rvk-0" @click="detailsClick(item[4])"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[4].Name.replace(/\s+/g, '') + '?gameId='+item[4].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[4].Name.replace(/\s+/g, '') + '?gameId='+item[4].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[4] && item[4].iconUrl" alt=""></a></div></div>
          </div>
          <div class="middle-left2" v-if="item[5] && item[5].iconUrl">
            <div class="item　sc-wr3rvk-0" @click="detailsClick(item[5])"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[5].Name.replace(/\s+/g, '') + '?gameId='+item[5].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[5].Name.replace(/\s+/g, '') + '?gameId='+item[5].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[5] && item[5].iconUrl" alt=""></a></div>
          </div>
          <div></div>
        </div>
        <div class="adv" v-if="index === 0">
<!--          <h6 class="adv-title">Advertisement</h6>-->
          <div v-if="!noAdvertisements" style="margin-top: 0.5625rem">
            <ins class="adsbygoogle"
                 style="display:block"
                 :data-ad-client="caPub"
                 :data-ad-slot="homeSlot"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
          </div>
          <div v-if="$originCopyWriting == 'marvelplay'" :id="gptId" ref="advRef" style="text-align: center"></div>
        </div>
        <div class="content-top" style="margin-top: 0.5625rem">
          <div class="item　sc-wr3rvk-0" @click="detailsClick(item[6])" v-if="item[6] && item[6].iconUrl"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[6].Name.replace(/\s+/g, '') + '?gameId='+item[6].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[6].Name.replace(/\s+/g, '') + '?gameId='+item[6].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[6] && item[6].iconUrl" alt=""></a></div>
          <div class="item　sc-wr3rvk-0" @click="detailsClick(item[7])" v-if="item[7] && item[7].iconUrl"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[7].Name.replace(/\s+/g, '') + '?gameId='+item[7].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[7].Name.replace(/\s+/g, '') + '?gameId='+item[7].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[7] && item[7].iconUrl" alt=""></a></div>
          <div class="item　sc-wr3rvk-0" @click="detailsClick(item[8])" v-if="item[8] && item[8].iconUrl"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[8].Name.replace(/\s+/g, '') + '?gameId='+item[8].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[8].Name.replace(/\s+/g, '') + '?gameId='+item[8].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[8] && item[8].iconUrl" alt=""></a></div>
        </div>
        <div class="content-bottom">
          <div class="item-box" v-if="item[9] && item[9].iconUrl"><div class="item　sc-wr3rvk-0" @click="detailsClick(item[9])"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[9].Name.replace(/\s+/g, '') + '?gameId='+item[9].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[9].Name.replace(/\s+/g, '') + '?gameId='+item[9].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[9] && item[9].iconUrl" alt=""></a></div></div>
          <div class="item-box" v-if="item[10] && item[10].iconUrl"><div class="item　sc-wr3rvk-0" @click="detailsClick(item[10])"><a :href="jumpSubdomain ? (newJumpUrl + '/M/details/'+item[10].Name.replace(/\s+/g, '') + '?gameId='+item[10].gameId+($route.query.channel ? ('&channel='+$route.query.channel): '')) : ('/M/details/'+item[10].Name.replace(/\s+/g, '') + '?gameId='+item[10].gameId+($route.query.channel ? ('&channel='+$route.query.channel): ''))"><img v-lazy="item[10] && item[10].iconUrl" alt=""></a></div></div>
        </div>
      </div>
    </div>
<!--    <div class="adv">-->
<!--      <div>-->
<!--        <ins class="adsbygoogle"-->
<!--             style="display:block"-->
<!--             data-ad-client="ca-pub-9543177256867205"-->
<!--             data-ad-slot="1039488300"-->
<!--             data-ad-format="auto"-->
<!--             data-full-width-responsive="true"></ins>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import {iconClickEscalation, recentGame} from '@/utils/utils.js';
export default {
  name: "AppList",
  props: ['appGameList'],
  data() {
    return {
      caPub: null,
      homeSlot: null,
      noAdvertisements: null,
      noAfc: null,
      gptId: null,
      jumpSubdomain: null,
      newJumpUrl: '',
    }
  },
  created() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { channel_ca_pub, m_home_slot, noAdvertisements, noAfc, m_home_gpt, jumpSubdomain } = channelInfo || {}
    this.caPub = channel_ca_pub
    this.homeSlot = m_home_slot
    this.noAdvertisements = noAdvertisements
    this.noAfc = noAfc
    let gpt = m_home_gpt && m_home_gpt.split(",") || []
    this.gptId = gpt[3]
    this.jumpSubdomain = jumpSubdomain
    // 跳转子域名详情页
    this.newJumpUrl = window.location.protocol + '//' + 'game01.' + window.location.hostname.split('.').slice(-2)[0] + '.' + window.location.hostname.split('.').slice(-2)[1]
  },
  mounted() {
    let channelInfo = localStorage.getItem('channelInfo') && JSON.parse(localStorage.getItem('channelInfo'))
    const { noAdvertisements, noAfc } = channelInfo || {}
    if (!noAdvertisements) {
      setTimeout(()=>{
        window.addAds()
      },1300)
    }
    console.log(this.$originCopyWriting == 'marvelplay');
    if (this.$originCopyWriting == 'marvelplay') {
      console.log(window.isDisplay);
      if (window.isDisplay) {
        googletag.cmd.push(function() { googletag.pubads().refresh(); });
      }

      let leftAdv = document.createElement("script")
      leftAdv.innerHTML = "googletag.cmd.push(function() { if(!window.isDisplay){googletag.display(mHome[3]);window.isDisplay = true};});"
      setTimeout(()=>{
        this.$refs.advRef[0].append(leftAdv)
      },600)
    }
  },
  methods: {
    detailsClick(item) {
      iconClickEscalation()
      recentGame(item)
      // this.$router.push({
      //   path: '/M/details',
      //   query: {
      //     gameId: item.gameId
      //   }
      // },()=>{})
    }
  }
}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.app-list{
  margin-top: 0.5625rem;
  .app-list-content{
    padding: 0 0.625rem;
    .content-top{
      width: 100%;
      box-sizing: border-box;
      .item{
        float: left;
        width: 5.875rem;
        height: 5.875rem;
        border-radius: 0.875rem;
        overflow: hidden;
        border: 2px solid #fff;
        img{
          width: 100%;
          height: 100%;
          background: white;
        }
      }
      .item:not(:last-child){
        margin-right: 0.561rem;
      }
    }
    .content-top:after{
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
    .content-middle{
      margin-top: 0.5625rem;
      margin-bottom: 0.5625rem;
      .middle-left{
        float: left;
        margin-right: 0.561rem;
        width: 12.3125rem;
        height: 12.3125rem;
        .item{
          width: 100%;
          height: 100%;
          border-radius: 0.875rem;
          overflow: hidden;
          border: 2px solid #fff;
          img{
            width: 100%;
            height: 100%;
            background: white;
          }
        }
      }
      .middle-left2{
        float: left;
        margin-left: 0.561rem;
        width: 12.3125rem;
        height: 12.3125rem;
        .item{
          width: 100%;
          height: 100%;
          border-radius: 0.875rem;
          overflow: hidden;
          border: 2px solid #fff;
          img{
            width: 100%;
            height: 100%;
            background: white;
          }
        }
      }
      .middle-right{
        float: left;
        .item-box{
          width: 5.875rem;
          height: 5.875rem;
          display: block;
          position: relative;
          background-color: #fff;
          box-shadow: 0 0.125rem 0.375rem 0 rgba(0,0,0,.6);
          border-radius: 0.875rem;
          .item{
            width: 100%;
            height: 100%;
            border-radius: 0.875rem;
            overflow: hidden;
            border: 2px solid #fff;
            img{
              width: 100%;
              height: 100%;
              background: white;
            }
          }
        }
        .item-box:not(:first-child){
          margin-top: 0.5625rem;
        }
      }
    }
    .content-middle:after{
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
    .content-bottom{
      margin-top: 0.5625rem;
      .item-box{
        float: left;
        width: 9.0625rem;
        height: 9.0625rem;
        display: block;
        position: relative;
        background-color: #fff;
        box-shadow: 0 0.125rem 0.375rem 0 rgba(0,0,0,.6);
        border-radius: 0.875rem;
        .item{
          width: 100%;
          height: 100%;
          border-radius: 0.875rem;
          overflow: hidden;
          border: 2px solid #fff;
          img{
            width: 100%;
            height: 100%;
            background: white;
          }
        }
      }
      .item-box:not(:first-child){
        margin-left: 0.624rem;
      }
    }
    .content-bottom:after{
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
  .adv{
    margin-top: 0.5625rem;
    .adv-title{
      background-color: #f7f7f7;
      line-height: 1.4375rem;
      font-size: .8125rem;
      color: #333;
      text-align: center;
    }
  }
}
</style>
