import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import { Button, Select, Input, Message, Form, FormItem } from 'element-ui'
import { determinePcOrMove, getUrlParams } from '@/utils/utils'
import dayjs from "dayjs"

Vue.prototype.$axios = axios
Vue.prototype.$message = Message
Vue.config.productionTip = false
// 域名
let originCopyWriting = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(-2)[0] : 'marvelplay'
let headToUpperCase = originCopyWriting.slice(0,1).toUpperCase() +originCopyWriting.slice(1).toLowerCase()
let suffix = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(-2)[1] : 'com'
Vue.prototype.$originCopyWriting = originCopyWriting
Vue.prototype.$headToUpperCase = headToUpperCase
Vue.prototype.$suffix = suffix

// 引入插件
import VueLazyload from 'vue-lazyload'
// 注册插件
Vue.use(VueLazyload,{
  loading:'' // 懒加载默认图片
})


window.addEventListener('beforeinstallprompt', (e) => {
  // 防止 Chrome 67 及更早版本自动显示安装提示
  e.preventDefault();
  console.log(e);
  // 稍后再触发此事件
  store.commit("changePWA",{deferredPrompt: e,deferredPromptType: true})
  setTimeout(()=>{
    store.state.deferredPrompt.userChoice.then((choiceResult) => {
      console.log(choiceResult.outcome);
      if (choiceResult.outcome === 'dismissed') {
        console.log('用户取消了安装');
      }
      else {
        console.log('用户已安装程序');

        store.commit("changePWA",{deferredPrompt: store.state.deferredPrompt,deferredPromptType: false})
      }
    });
  })
});
// 监听横屏竖屏切换
window.addEventListener('orientationchange', function() {
  if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    setTimeout(()=>{
      //fontsize计算
      document.documentElement.style.fontSize = document.documentElement.clientWidth/320 * 16 +'px'
    },100)
  }
});

// 修改title
Vue.directive('title', {
  // 单个修改标题
  update: function (el) {
    console.log(el.dataset.title);
    if (el.dataset.title) {
      setTimeout(()=>{
        document.title = el.dataset.title
      })
    }
  }
})

// 解决地址栏参数在/#前面问题
let b = new URL(window.location.href);
console.log(b);
if (b.pathname === '/' || b.hash === '#/') {
  if (determinePcOrMove() == 2) {
    if (b.search) {
      history.replaceState(null,null,`${b.origin + b.pathname}P/homeIndex${b.search}`)
      console.log(window.location.href);
    } else if (getUrlParams('channel')){
      history.replaceState(null,null,`${b.origin + b.pathname}P/homeIndex?channel=${getUrlParams('channel')}`)
    } else {
      history.replaceState(null,null,`${b.origin + b.pathname}P/homeIndex`)
      console.log(window.location.href);
    }
  } else {
    if (b.search) {
      history.replaceState(null,null,`${b.origin + b.pathname}M/homeIndex${b.search}`)
    } else if (getUrlParams('channel')){
      history.replaceState(null,null,`${b.origin + b.pathname}M/homeIndex?channel=${getUrlParams('channel')}`)
    } else {
      history.replaceState(null,null,`${b.origin + b.pathname}M/homeIndex`)
    }
  }
}

// 动态修改pwa
let myDynamicManifest = {
  "short_name": `${originCopyWriting}.${suffix}`,
  "name": `${originCopyWriting}.${suffix}`,
  "icons": [
    {
      "src": `${window.location.origin}/favicon.ico`,
      "sizes": "192x192",
      "type": "image/png"
    },
    {
      "src": `${window.location.origin}/favicon.ico`,
      "sizes": "512x512",
      "type": "image/png"
    }
  ],
  "start_url": getUrlParams('channel') ? `${window.location.origin}?pwa=client&channel=${getUrlParams('channel')}` : `${window.location.origin}?pwa=client`,
  "display": "standalone",
  "background_color": "#0054ff",
  "theme_color": "#080403",
  // "description": `${headToUpperCase} is an online gaming website where you will find various types of games`,
  // "screenshots": [
  //   {
  //     "src": `${window.location.origin}/img/install/1920x1080-1.webp`,
  //     "sizes": "1920x1080",
  //     "type": "image/webp",
  //     "form_factor": "wide"
  //   },
  //   {
  //     "src": `${window.location.origin}/img/install/1920x1080-2.webp`,
  //     "sizes": "1920x1080",
  //     "type": "image/webp",
  //     "form_factor": "wide"
  //   },
  //   {
  //     "src": `${window.location.origin}/img/install/1920x1080-3.webp`,
  //     "sizes": "1920x1080",
  //     "type": "image/webp",
  //     "form_factor": "wide"
  //   },
  //   {
  //     "src": `${window.location.origin}/img/install/1920x1080-4.webp`,
  //     "sizes": "1920x1080",
  //     "type": "image/webp",
  //     "form_factor": "wide"
  //   },
  //   {
  //     "src": `${window.location.origin}/img/install/1920x1080-5.webp`,
  //     "sizes": "1920x1080",
  //     "type": "image/webp",
  //     "form_factor": "wide"
  //   },
  //   {
  //     "src": `${window.location.origin}/img/install/1920x1080-6.webp`,
  //     "sizes": "1920x1080",
  //     "type": "image/webp",
  //     "form_factor": "wide"
  //   }
  // ]
}
const stringManifest = JSON.stringify(myDynamicManifest);
const blob = new Blob([stringManifest], {type: 'application/json'});
const manifestURL = URL.createObjectURL(blob);
document.querySelector('#manifest').setAttribute('href', manifestURL)

// 监听点击广告离开页面
let LeavingThePage = false
document.addEventListener("visibilitychange", function() {
  if (document.hidden) {
    // 当前标签页被切换到后台
    setTimeout(()=>{
      console.log(LeavingThePage);
      if (LeavingThePage == true) {
        console.log("标签页被切换到后台");
        if(typeof(dataLayer) != 'undefined'){
          // 点击首页广告
          if (window.location.href.indexOf('/homeIndex') > -1) {
            dataLayer.push({'event':'click_home_ad'});
          }
        }
        // 点击穿插广告
        if (window.location.href.split('#')[1] == 'google_vignette') {
          let insertAdNum = localStorage.getItem('insertAdClick')
          localStorage.setItem('insertAdClick',insertAdNum ? String(Number(insertAdNum) + 1) : '1')
          localStorage.setItem('insertAdClickTime',dayjs().format('YYYY-MM-DD'))
          if(typeof(dataLayer) != 'undefined'){
            dataLayer.push({'event':'user_click_vignette','vignette_clicked_num':localStorage.getItem('insertAdClick')});
          }
        }
        // 点击任意广告
        let arbitrarilyAdv = localStorage.getItem('arbitrarilyAdvClick')
        localStorage.setItem('arbitrarilyAdvClick',arbitrarilyAdv ? String(Number(arbitrarilyAdv) + 1) : '1')
        localStorage.setItem('arbitrarilyAdvClickTime',dayjs().format('YYYY-MM-DD'))
        if(typeof(dataLayer) != 'undefined'){
          dataLayer.push({'event':'user_click_ad','ad_clicked_num':localStorage.getItem('arbitrarilyAdvClick')});
        }
        LeavingThePage = false
      }
    },300)

    if (window.location.href.indexOf('/details/') > -1) {
      let timeLength = Number(localStorage.getItem('timeOn')) ? (new Date().getTime() - Number(localStorage.getItem('timeOn')) + Number(localStorage.getItem('timeLength') ? localStorage.getItem('timeLength') : 0)) : 0
      localStorage.setItem('timeLength',timeLength.toString())
      store.commit('changePageHiddenTypeClick',true)
    }
  } else {
    // 当前标签页被切换到前台
    console.log("标签页被切换到前台");

    window.focus()

    if (window.location.href.indexOf('/details/') > -1) {
      let timeOn = Number(localStorage.getItem('timeOn')) ? new Date().getTime() : 0
      localStorage.setItem('timeOn',timeOn.toString())
      store.commit('changePageHiddenTypeClick',false)
    }
  }
});
window.addEventListener('blur',()=>{
  setTimeout(()=>{
    if (document.activeElement.tagName==='IFRAME'){
      LeavingThePage = true
      setTimeout(()=>{
        LeavingThePage = false
        // window.blur()
        // window.focus()
      },1200)
    }else {
      LeavingThePage = false
    }
  })
})

// 初始进入页面上报
if (!sessionStorage.getItem('pixelType') && originCopyWriting == 'ajoy') {
  // bge && bge('event', 'consult', {configId: '903985143796275456'});
  sessionStorage.setItem('pixelType','1')
}

// 点击游戏图标次数
if (dayjs(dayjs().format('YYYY-MM-DD')).isAfter(dayjs(localStorage.getItem('iconClickTime')))) {
  localStorage.setItem('iconClick','0')
  localStorage.setItem('iconClickTime',dayjs().format('YYYY-MM-DD'))
}
// 点击广告次数
if (dayjs(dayjs().format('YYYY-MM-DD')).isAfter(dayjs(localStorage.getItem('arbitrarilyAdvClickTime')))) {
  localStorage.setItem('arbitrarilyAdvClick','0')
  localStorage.setItem('arbitrarilyAdvClickType','0')
  localStorage.setItem('arbitrarilyAdvClickTime',dayjs().format('YYYY-MM-DD'))
}
// 点击穿插广告次数
if (dayjs(dayjs().format('YYYY-MM-DD')).isAfter(dayjs(localStorage.getItem('insertAdClickTime')))) {
  localStorage.setItem('insertAdClick','0')
  localStorage.setItem('insertAdClickTime',dayjs().format('YYYY-MM-DD'))
}


Vue.use(Button)
Vue.use(Select)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
