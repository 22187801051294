<template>
  <div id="app">
<!--    <div v-if="determinePcOrMove == 2">-->
<!--      <Navigation v-if="navHide"></Navigation>-->
<!--      <router-view></router-view>-->
<!--&lt;!&ndash;      <BottomNav v-if="navHide"></BottomNav>&ndash;&gt;-->
<!--    </div>-->
<!--    <div v-else>-->
<!--      <router-view></router-view>-->
<!--    </div>-->
    <div v-if="loadOk">
      <router-view></router-view>
    </div>
    <div style="display: contents" v-if="smegmaType">
      <div class="AFSJx dgmlwU" style="background-color: rgba(18,125,171)">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
// import Navigation from './components/Navigation';
import { determinePcOrMove } from '@/utils/utils.js'
export default {
  name: 'App',
  components: {
    // Navigation
  },
  data() {
    return {
      determinePcOrMove: determinePcOrMove(),
      navHide: true,
      loadOk: false,
      smegmaType:false
    }
  },
  created() {
    this.smegmaType = true
  },
  mounted() {
    this.timer = setInterval(()=> {
      if (window.loadOk) {
        clearInterval(this.timer)
        setTimeout(()=>{
          this.smegmaType = false
        },500)
        this.loadOk = true
      }
    },500)
    console.log(this.$route);
    const { name } = this.$route
    if (name == 'privacy' || name == 'contactUs') {
      this.navHide = false
    }
  }
}
</script>
<style lang="less">
.el-loading-spinner{
  font-size: 50px;
}
@keyframes djqRmU{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes etkMLf{
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.dgmlwU{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: djqRmU .2s cubic-bezier(.25, .1, .25, 1) both;
  z-index: 100001;
}
.AFSJx{
  display: flex;
  align-items: center;
  justify-content: center;
  div{
    width: 18px;
    height: 18px;
    margin: 0 2px;
    border-radius: 100%;
    background: white;
    animation: 1.4s ease-in-out 0s infinite normal both running etkMLf;
  }
  div:nth-child(2){
    animation-delay: -0.16s;
  }
}
</style>
<style>

</style>
