import { render, staticRenderFns } from "./BottomText.vue?vue&type=template&id=5c860d86&scoped=true&"
import script from "./BottomText.vue?vue&type=script&lang=js&"
export * from "./BottomText.vue?vue&type=script&lang=js&"
import style0 from "./BottomText.vue?vue&type=style&index=0&id=5c860d86&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c860d86",
  null
  
)

export default component.exports